import Unauthenticated from "../layouts/Unauthenticated";
import Default from "../layouts/Default";
import {h} from "vue";
import {RouterView} from 'vue-router';

const authenticated = {
    path: "",
    redirect: "dashboard",
    // component: (typeof window !== 'undefined' && window.ReactNativeWebView) ? Mobile : Standard,
    component: Default,
    children: [
        {
            path: '/export/download/:id',
            name: 'export',
            component: () => import("../pages/Export/Export.vue"),
            meta: {
                requiresAuth: true,
                title: "page-title-export",
            },
        },
        {
            path: "dashboard",
            name: "dashboard",
            component: () => import("../pages/Dashboard/Dashboard.vue"),
            meta: {
                requiresAuth: true,
                title: "page-title-dashboard",
            },
        },

        /*{
            path: "test",
            name: "test",
            component: () => import("../pages/TestDate.vue"),
            meta: {
                requiresAuth: true,
                title: "page-title-test",
            },
        },*/

        {
            path: "customers",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "customer-list",
                    component: () => import("../pages/Customer/CustomerList/CustomerList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-customer-list",
                    }
                },
                {
                    path: "add",
                    name: "customer-add",
                    component: () => import("../pages/Customer/CustomerAddEdit/CustomerAdd.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-customer-add",
                    }
                },
                {
                    path: ":id",
                    name: "customer-detail",
                    component: () => import("../pages/Customer/CustomerDetail/CustomerDetail.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-customer-detail",
                    }
                },
                {
                    path: ":id/edit",
                    name: "customer-edit",
                    component: () => import("../pages/Customer/CustomerAddEdit/CustomerEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-customer-edit",
                    }
                },
                {
                    path: "keyid-media",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "customer-keyid-media-list",
                            component: () => import("../pages/Customer/KeyidMedia/KeyidMediaList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-keyid-media-list",
                            }
                        },
                        {
                            path: "add",
                            name: "customer-keyid-media-add",
                            component: () => import("../pages/Customer/KeyidMedia/KeyidMediaAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-keyid-media-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "customer-keyid-media-edit",
                            component: () => import("../pages/Customer/KeyidMedia/KeyidMediaEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-keyid-media-edit",
                            }
                        }
                    ]
                },
                {
                    path: "cost-center",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "customer-cost-center-list",
                            component: () => import("../pages/Customer/CostCenter/CostCenter.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-cost-center-list",
                            }
                        },
                        {
                            path: "add",
                            name: "customer-cost-center-add",
                            component: () => import("../pages/Customer/CostCenter/CostCenterAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-cost-center-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "customer-cost-center-edit",
                            component: () => import("../pages/Customer/CostCenter/CostCenterEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-cost-center-edit",
                            }
                        }
                    ]
                },
                {
                    path: "affiliate",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "customer-affiliate-list",
                            component: () => import("../pages/Customer/Affiliate/AffiliateList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-affiliate-list",
                            }
                        },
                        {
                            path: "add",
                            name: "customer-affiliate-add",
                            component: () => import("../pages/Customer/Affiliate/AffiliateAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-affiliate-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "customer-affiliate-edit",
                            component: () => import("../pages/Customer/Affiliate/AffiliateEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-affiliate-edit",
                            }
                        }
                    ]
                },
                {
                    path: "payment-account",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "customer-payment-account-list",
                            component: () => import("../pages/Customer/PaymentAccount/PaymentAccountList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-payment-account-list",
                            }
                        },
                        {
                            path: "add",
                            name: "customer-payment-account-add",
                            component: () => import("../pages/Customer/PaymentAccount/PaymentAccountAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-payment-account-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "customer-payment-account-edit",
                            component: () => import("../pages/Customer/PaymentAccount/PaymentAccountEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-payment-account-edit",
                            }
                        },
                    ]
                },
                {
                    path: "notifications",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "customer-notifications",
                            component: () => import("../pages/Provider/ProviderNotifications/ProviderNotifications.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-notifications",
                            }
                        },
                        {
                            path: "add",
                            name: "customer-notifications-add",
                            component: () => import("../pages/Provider/ProviderNotifications/ProviderNotificationsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-notifications-add",
                            }
                        },
                        {
                            path: "edit/:id",
                            name: "customer-notifications-edit",
                            component: () => import("../pages/Provider/ProviderNotifications/ProviderNotificationsEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-customer-notifications-edit",
                            }
                        }
                    ]
                },
                {
                    path: "settings",
                    name: "customer-settings",
                    component: () => import("../pages/Customer/CustomerSettings/CustomerSettings.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-customer-settings",
                    }
                }
            ]
        },


        // CRM
        {
            path: "crm",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "crm-cases-list",
                    component: () => import("../pages/CRM/CRMCases/CRMCasesList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-crm",
                    }
                },
                {
                    path: "add",
                    name: "crm-cases-add",
                    component: () => import("../pages/CRM/CRMCases/CRMCaseAdd.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-crm-add",
                    }
                },
                {
                    path: ":id",
                    name: "crm-cases-detail",
                    component: () => import("../pages/CRM/CRMCases/CRMCase.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-crm-detail",
                    }
                },
                {
                    path: ":id/edit",
                    name: "crm-cases-edit",
                    component: () => import("../pages/CRM/CRMCases/CRMCaseEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-crm-edit",
                    }
                },
                {
                    path: "settings",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "crm-settings",
                            component: () => import("../pages/CRM/CRMSettings/CRMSettings.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-crm-settings",
                            }
                        },
                        {
                            path: "subTopics",
                            name: "crm-settings-subTopics",
                            component: () => import("../pages/CRM/CRMSettings/CRMSubTopicSettings.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-crm-settings",
                            }
                        }
                    ]
                }
            ]
        },


        // Bookings
        {
            path: "bookings",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "booking-list",
                    component: () => import("../pages/Booking/BookingList/BookingList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-list",
                    }
                },
                {
                    path: "add",
                    name: "booking-add",
                    component: () => import("../pages/Booking/BookingAdd/BookingAdd.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-add",
                    }
                },
                {
                    path: ":id",
                    name: "booking-detail",
                    component: () => import("../pages/Booking/BookingDetail/BookingDetail.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-detail",
                    }
                },
                {
                    path: ":id/edit",
                    name: "booking-edit",
                    component: () => import("../pages/Booking/BookingEdit/BookingEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-edit",
                    }
                },
                {
                    path: "timeline",
                    name: "booking-timeline",
                    component: () => import("../pages/Booking/BookingTimeline/BookingTimeline.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-timeline",
                    }
                },
                {
                    path: "calendar",
                    name: "booking-calendar",
                    component: () => import("../pages/Booking/BookingCalendar/BookingCalendar.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-calendar",
                    }
                },
                {
                    path: "feedback",
                    name: "booking-feedback",
                    component: () => import("../pages/Booking/BookingFeedback/BookingFeedback.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-feedback",
                    }
                },
                {
                    path: "deleted",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "booking-deleted",
                            component: () => import("../pages/Booking/BookingDeleted/BookingDeletedList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-booking-deleted",
                            }
                        },
                        {
                            path: ":id",
                            name: "booking-deleted-detail",
                            component: () => import("../pages/Booking/BookingDeleted/BookingDeletedDetail.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-booking-deleted-detail",
                            }
                        },
                    ]
                },
                {
                    path: "restrictions",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "booking-restrictions",
                            component: () => import("../pages/Booking/BookingRestrictions/BookingRestrictions.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-booking-restrictions",
                            }
                        },
                        {
                            path: "operating-hours/:id",
                            name: "booking-restrictions-operating-hours-edit",
                            component: () => import("../pages/Booking/BookingRestrictions/BookingRestrictionsOperatingHoursEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-booking-restrictions-operating-hours-edit",
                            }
                        },
                        {
                            path: "special-operating-hours/add",
                            name: "booking-restrictions-special-operating-hours-add",
                            component: () => import("../pages/Booking/BookingRestrictions/BookingRestrictionsSpecialOperatingHoursAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-booking-restrictions-special-operating-hours-add",
                            }
                        },
                        {
                            path: "special-operating-hours/:id",
                            name: "booking-restrictions-special-operating-hours-edit",
                            component: () => import("../pages/Booking/BookingRestrictions/BookingRestrictionsSpecialOperatingHoursEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-booking-restrictions-special-operating-hours-edit",
                            }
                        },
                        {
                            path: "usage",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "booking-restrictions-usage",
                                    component: () => import("../pages/Booking/BookingRestrictions/Usage/BookingRestrictionsUsage.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-booking-restrictions-usage",
                                    }
                                },
                                {
                                    path: "add",
                                    name: "booking-restrictions-usage-add",
                                    component: () => import("../pages/Booking/BookingRestrictions/Usage/BookingRestrictionsUsageAdd.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-booking-restrictions-usage-add",
                                    }
                                },
                                {
                                    path: ":id",
                                    name: "booking-restrictions-usage-edit",
                                    component: () => import("../pages/Booking/BookingRestrictions/Usage/BookingRestrictionsUsageEdit.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-booking-restrictions-usage-edit",
                                    }
                                }
                            ]
                        },
                        {
                            path: "user",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "booking-restrictions-user",
                                    component: () => import("../pages/Booking/BookingRestrictions/User/BookingRestrictionsUser.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-booking-restrictions-user",
                                    }
                                },
                                {
                                    path: "add",
                                    name: "booking-restrictions-user-add",
                                    component: () => import("../pages/Booking/BookingRestrictions/User/BookingRestrictionsUserAdd.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-booking-restrictions-user-add",
                                    }
                                },
                                {
                                    path: ":id",
                                    name: "booking-restrictions-user-edit",
                                    component: () => import("../pages/Booking/BookingRestrictions/User/BookingRestrictionsUserEdit.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-booking-restrictions-user-edit",
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    path: "settings",
                    name: "booking-settings",
                    component: () => import("../pages/Booking/BookingSettings/BookingSettings.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-booking-settings",
                    }
                }
            ]
        },


        // Vehicles

        {
            path: "vehicles",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "vehicle-management-list",
                    component: () =>
                        import("../pages/Vehicle/Management/VehicleManagementList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-list",
                    },
                },
                {
                    path: "add",
                    name: "vehicle-management-add",
                    component: () =>
                        import("../pages/Vehicle/Management/VehicleManagementAdd.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-add",
                    },
                },
                {
                    path: ":id",
                    name: "vehicle-management-detail",
                    component: () =>
                        import("../pages/Vehicle/Management/VehicleManagementDetail.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-detail",
                    },
                },
                {
                    path: ":id/edit",
                    name: "vehicle-management-edit",
                    component: () =>
                        import("../pages/Vehicle/Management/VehicleManagementEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-edit",
                    },
                },
                {
                    path: 'models',
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-models-list",
                            component: () =>
                                import("../pages/Vehicle/VehicleModels/VehicleModelsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-model-list",
                            },
                        },
                        {
                            path: "add",
                            name: "vehicle-models-add",
                            component: () =>
                                import("../pages/Vehicle/VehicleModels/VehicleModelsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-model-add",
                            },
                        },
                        {
                            path: ":id",
                            name: "vehicle-models-edit",
                            component: () =>
                                import("../pages/Vehicle/VehicleModels/VehicleModelsEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-model-edit",
                            },
                        },
                    ]
                },
                {
                    path: "map",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-map",
                            component: () => import("../pages/Vehicle/Map/Map.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-map",
                            },
                        },
                    ]
                },
                {
                    path: "map-tracking",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-map-tracking",
                            component: () => import("../pages/Vehicle/MapTracking/MapTracking.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-map-tracking",
                            },
                        },
                    ]
                },
                {
                    path: "costs",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-costs-list",
                            component: () => import("../pages/Vehicle/Costs/CostsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-cost-list",
                            },
                        },

                        {
                            path: ":id",
                            name: "vehicle-costs-edit",
                            component: () => import("../pages/Vehicle/Costs/CostsEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-cost-edit",
                            },
                        },

                        {
                            path: "add",
                            name: "vehicle-costs-add",
                            component: () => import("../pages/Vehicle/Costs/CostsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-cost-add",
                            },
                        },
                    ]
                },
                {
                    path: "fleets",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-fleets-list",
                            component: () =>
                                import("../pages/Vehicle/FleetsManagement/FleetsManagement.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-fleet-list",
                            },
                        },


                        {
                            path: "add",
                            name: "vehicle-fleets-add",
                            component: () =>
                                import("../pages/Vehicle/FleetsManagement/Fleets/FleetsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-fleet-add",
                            },
                        },

                        {
                            path: ":id",
                            name: "vehicle-fleets-edit",
                            component: () =>
                                import("../pages/Vehicle/FleetsManagement/Fleets/FleetsEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-fleet-edit",
                            },
                        },
                        {
                            path: "locations",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "vehicle-fleets-locations-list",
                                    component: () =>
                                        import("../pages/Vehicle/FleetsManagement/FleetsManagement.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-vehicle-fleet-location-list",
                                    },
                                    props: true,
                                },

                                {
                                    path: ":id",
                                    name: "vehicle-fleets-locations-edit",
                                    title: "page-title-vehicle-fleet-location-edit",

                                    component: () =>
                                        import(
                                            "../pages/Vehicle/FleetsManagement/FleetsLocations/FleetsLocationsEdit.vue"
                                            ),
                                    meta: {
                                        requiresAuth: true,
                                    },
                                },

                                {
                                    path: "add",
                                    name: "vehicle-fleets-locations-add",
                                    component: () =>
                                        import(
                                            "../pages/Vehicle/FleetsManagement/FleetsLocations/FleetsLocationsAdd.vue"
                                            ),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-vehicle-fleet-location-add",
                                    },
                                },
                            ]
                        },
                        {
                            path: "poi",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "vehicle-fleets-poi-list",
                                    component: () =>
                                        import("../pages/Vehicle/FleetsManagement/FleetsManagement.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-vehicle-fleet-location-list",
                                    },
                                    props: true,
                                },

                                {
                                    path: ":id",
                                    name: "vehicle-fleets-poi-edit",
                                    title: "page-title-vehicle-fleet-location-edit",

                                    component: () =>
                                        import(
                                            "../pages/Vehicle/FleetsManagement/POI/POIEdit.vue"
                                            ),
                                    meta: {
                                        requiresAuth: true,
                                    },
                                },

                                {
                                    path: "add",
                                    name: "vehicle-fleets-poi-add",
                                    component: () =>
                                        import(
                                            "../pages/Vehicle/FleetsManagement/POI/POIAdd.vue"
                                            ),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-vehicle-fleet-location-add",
                                    },
                                },
                            ]
                        }
                    ]
                },


                {
                    path: "telemetry-devices",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-telemetry-devices-list",
                            component: () =>
                                import("../pages/Vehicle/TelemetryDevices/TelemetryDevicesList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-telemetry-device-list",
                            },
                        },

                        {
                            path: "add",
                            name: "vehicle-telemetry-devices-add",
                            component: () =>
                                import("../pages/Vehicle/TelemetryDevices/TelemetryDevicesAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-telemetry-device-add",
                            },
                        },

                        {
                            path: ":id",
                            name: "vehicle-telemetry-devices-edit",
                            component: () =>
                                import("../pages/Vehicle/TelemetryDevices/TelemetryDevicesEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-telemetry-device-edit",
                            },
                        },
                    ]
                },
                {
                    path: "damage-cases",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-damage-cases-list",
                            component: () =>
                                import("../pages/Vehicle/DamageCases/DamageCasesList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-damage-cases-list",
                            },
                        },
                        {
                            path: "add",
                            name: "vehicle-damage-cases-add",
                            component: () =>
                                import("../pages/Vehicle/DamageCases/DamageCasesAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-damage-cases-add",
                            },
                        },
                        {
                            path: ":id",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "vehicle-damage-cases-detail",
                                    component: () =>
                                        import("../pages/Vehicle/DamageCases/DamageCasesDetail.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-vehicle-damage-cases-detail",
                                    },
                                },
                                {
                                    path: "edit",
                                    name: "vehicle-damage-cases-edit",
                                    component: () =>
                                        import("../pages/Vehicle/DamageCases/DamageCasesEdit.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-vehicle-damage-cases-edit",
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "settings",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "vehicle-settings",
                            component: () =>
                                import("../pages/Vehicle/VehicleSettings/VehicleSettings.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-vehicle-settings",
                            },
                        },
                    ]
                }
            ]
        },

        // vehicle files
        {
            path: "vehicle-files",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "vehicle-files-basic-data",
                    component: () =>
                        import("../pages/VehicleFiles/BasicData/VehicleFilesBasicDataList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-files-basic-data-list",
                    },

                },
                {
                    path: "add",
                    name: "vehicle-files-basic-data-add",
                    component: () =>
                        import("../pages/VehicleFiles/BasicData/VehicleFilesBasicDataAdd.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-files-basic-data-add",
                    },
                },
                {
                    path: ":id/edit",
                    name: "vehicle-files-basic-data-edit",
                    component: () =>
                        import("../pages/VehicleFiles/BasicData/VehicleFilesBasicDataEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-files-basic-data-edit",
                    },
                },
                {
                    path: ":id",
                    name: "vehicle-files-basic-data-detail",
                    component: () =>
                        import("../pages/VehicleFiles/BasicData/VehicleFilesBasicDataDetail.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-files-basic-data-detail",
                    },

                },
                {
                    path: "uploads",
                    name: "vehicle-files-uploads",
                    component: () =>
                        import("../pages/VehicleFiles/Uploads/VehicleFilesUploadsList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-vehicle-files-upload",
                    },

                },

            ]
        },

        // accounting
        {
            path: "accounting",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "accounting-dashboard",
                    component: () => import("../pages/Accounting/AccountingView.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-accounting-dashboard",
                    },
                },
                {
                    path: "registration-tariffs",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-registration-tariffs-list",
                            component: () => import("../pages/Accounting/RegistrationTariffs/RegistrationTariffsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-registration-tariffs-list",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-registration-tariffs-add",
                            component: () => import("../pages/Accounting/RegistrationTariffs/RegistrationTariffsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-registration-tariffs-add",
                            },
                        },
                        {
                            path: ":id",
                            name: "accounting-registration-tariffs-edit",
                            component: () => import("../pages/Accounting/RegistrationTariffs/RegistrationTariffsEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-registration-tariffs-edit",
                            },
                        }
                    ],
                },
                {
                    path: "billing",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-billing-list",
                            component: () => import("../pages/Accounting/Billing/BillingList/BillingList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-billing-list",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-billing-add",
                            component: () => import("../pages/Accounting/Billing/BillingAdd/BillingAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-billing-add",
                            },
                        },
                        {
                            path: ":id",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "accounting-billing-detail",
                                    component: () => import("../pages/Accounting/Billing/BillingDetail/BillingDetail.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-accounting-billing-detail",
                                    },
                                },
                                {
                                    path: "skipped-bookings",
                                    name: "accounting-billing-skipped-bookings",
                                    component: () => import("../pages/Accounting/Billing/BillingDetail/BillingSkippedBookings.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-accounting-billing-skipped-bookings",
                                    }
                                }
                            ]
                        },

                    ]
                },
                {
                    path: "additional-fee",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-additional-fee-list",
                            component: () => import("../pages/Accounting/AdditionalFee/AdditionalFeeList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-additional-fee-list",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-additional-fee-add",
                            component: () => import("../pages/Accounting/AdditionalFee/AdditionalFeeAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-additional-fee-add",
                            },
                        },
                        {
                            path: ":id",
                            name: "accounting-additional-fee-edit",
                            component: () => import("../pages/Accounting/AdditionalFee/AdditionalFeeEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-additional-fee-edit",
                            },
                        }
                    ]
                },
                // Instant Payments
                {
                    path: "instant-payments",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-instant-payments-bookings",
                            component: () => import("../pages/Accounting/InstantPayments/InstantPaymentsBookingsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-instant-payments-list-bookings",
                            },
                        },
                        {
                            path: "additional-fees",
                            name: "accounting-instant-payments-additional-fees",
                            component: () => import("../pages/Accounting/InstantPayments/InstantPaymentsAdditionalFeesList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-instant-payments-list-additional-fees",
                            },
                        }
                    ]
                },

                // Accounting Settings
                {
                    path: "accounting-settings",
                    name: "accounting-settings",
                    component: () => import("../pages/Accounting/AccountingSettings/AccountingSettings.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-accounting-settings",
                    },
                },

                // Tariffs
                {
                    path: "tariffs",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-tariffs-list",
                            component: () => import("../pages/Accounting/Tariffs/TariffsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-tariffs-list",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-tariffs-add",
                            component: () => import("../pages/Accounting/Tariffs/TariffsAdd/TariffsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-tariffs-add",
                            },
                        },
                        {
                            path: ":id",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "accounting-tariffs-detail",
                                    component: () => import("../pages/Accounting/Tariffs/TariffsDetail/TariffsDetail.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-accounting-tariffs-detail",
                                    },
                                },
                                {
                                    path: "edit",
                                    name: "accounting-tariffs-edit",
                                    component: () => import("../pages/Accounting/Tariffs/TariffsEdit/TariffsEdit.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-accounting-tariffs-edit",
                                    },
                                }
                            ]
                        }
                    ]
                },
                // Usage Tariffs
                {
                    path: "usage-tariffs",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-usage-tariffs-list",
                            component: () => import("../pages/Accounting/Tariffs/UsageTariffsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-usage-tariffs-list",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-usage-tariffs-add",
                            component: () => import("../pages/Accounting/Tariffs/UsageTariffs/UsageTariffsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-usage-tariffs-add",
                            },
                        },
                        {
                            path: ":usageTariffId",
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "",
                                    name: "accounting-usage-tariffs-detail",
                                    component: () => import("../pages/Accounting/Tariffs/UsageTariffs/UsageTariffsDetail.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-accounting-usage-tariffs-detail",
                                    },
                                },
                                {
                                    path: "edit",
                                    name: "accounting-usage-tariffs-edit",
                                    component: () => import("../pages/Accounting/Tariffs/UsageTariffs/UsageTariffsEdit.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-accounting-usage-tariffs-edit",
                                    },
                                }
                            ]
                        }
                    ]
                },

                // Trip Packages
                {
                    path: "trip-packages",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-trip-packages-list",
                            component: () => import("../pages/Accounting/Tariffs/TripPackagesList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-trip-packages-list",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-trip-packages-add",
                            component: () => import("../pages/Accounting/Tariffs/TripPackages/TripPackagesAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-trip-packages-add",
                            },
                        },
                        {
                            path: ":tripPackageId",
                            name: "accounting-trip-packages-edit",
                            component: () => import("../pages/Accounting/Tariffs/TripPackages/TripPackagesEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-trip-packages-edit",
                            },
                        }
                    ]
                },
                {
                    path: "cost-overview",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-cost-overview-list",
                            component: () => import("../pages/Accounting/CostOverview/CostOverviewList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-accounting-cost-overview-list",
                            },
                        },
                    ]
                },
                {
                    path: "invoice-details",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "accounting-invoice-details-list",
                            component: () => import("../pages/Accounting/Invoice/InvoiceDetailsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-invoice-details-edit",
                            },
                        },
                        {
                            path: "add",
                            name: "accounting-invoice-details-add",
                            component: () => import("../pages/Accounting/Invoice/InvoiceDetailsAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-invoice-details-edit",
                            },
                        },
                        {
                            path: ":id",
                            name: "accounting-invoice-details-edit",
                            component: () => import("../pages/Accounting/Invoice/InvoiceDetailsEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-invoice-details-edit",
                            },
                        }
                    ]
                }
            ]
        },


        // reporting
        {
            path: "reporting",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "kpi-list",
                    component: () => import("../pages/Reporting/KPI/KPIList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-reporting-kpi",
                    },
                },
                {
                    path: "reports",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: ":id",
                            name: "kpi-detail",
                            component: () => import("../pages/Reporting/KPI/KPIDetail.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-reporting-kpi-detail",
                            },
                        }
                    ]
                },
                {
                    path: "denied-vehicle-access",
                    name: "reporting-denied-vehicle-access-list",
                    component: () => import("../pages/Reporting/DeniedVehicleAccess/DeniedVehicleAccessList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-reporting-denied-vehicle-access",
                    },
                },
                {
                    path: "sentinel",
                    name: "sentinel-list",
                    component: () => import("../pages/Vehicle/Sentinel/SentinelApiList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-sentinel-list",
                    },
                },
                {
                    path: "exports",
                    name: "exports-list",
                    component: () => import("../pages/Reporting/Exports/ExportsList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-exports-list",
                    },
                }
            ]
        },


        // FAQ
        {
            path: "/faq",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "topic",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "", // /faq/topic
                            name: "faq-topic-list",
                            component: () => import("../pages/Faq/TopicsList/TopicsList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-faq-topic-list",
                            },
                        },
                        {
                            path: "add", // /faq/topic/add
                            name: "faq-topic-add",
                            component: () => import("../pages/Faq/TopicAdd/TopicAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-faq-topic-add",
                            },
                        },
                        {
                            path: ":topicId", // /faq/topic/:topicId
                            component: {render: () => h(RouterView)},
                            children: [
                                {
                                    path: "", // /faq/topic/:topicId
                                    name: "faq-topic-edit",
                                    component: () => import("../pages/Faq/TopicEdit/TopicEdit.vue"),
                                    meta: {
                                        requiresAuth: true,
                                        title: "page-title-faq-topic-edit",
                                    },
                                },
                                {
                                    path: "question", // /faq/topic/:topicId/question
                                    component: {render: () => h(RouterView)},
                                    children: [
                                        {
                                            path: "", // /faq/topic/:topicId/question
                                            name: "faq-topic-question-list",
                                            component: () =>
                                                import("../pages/Faq/QuestionList/QuestionList.vue"),
                                            meta: {
                                                requiresAuth: true,
                                                title: "page-title-faq-question-list",
                                            },
                                        },
                                        {
                                            path: "add", // /faq/topic/:topicId/question/add
                                            name: "faq-topic-question-add",
                                            component: () =>
                                                import("../pages/Faq/QuestionAdd/QuestionAdd.vue"),
                                            meta: {
                                                requiresAuth: true,
                                                title: "page-title-faq-question-add",
                                            },
                                        },
                                        {
                                            path: ":questionId", // /faq/topic/:topicId/question/:questionId
                                            name: "faq-topic-question-edit",
                                            component: () =>
                                                import("../pages/Faq/QuestionEdit/QuestionEdit.vue"),
                                            meta: {
                                                requiresAuth: true,
                                                title: "page-title-faq-question-edit",
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },


        // providers
        {
            path: "provider",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "provider-list",
                    component: () => import("../pages/Provider/ProviderList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-provider-list",
                    }
                },
                {
                    path: "add",
                    name: "provider-add",
                    component: () => import("../pages/Provider/ProviderAdd/ProviderAdd.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-provider-add",
                    }
                },
                {
                    path: "settings",
                    name: "provider-settings",
                    component: () => import("../pages/Provider/ProviderSettings/ProviderSettings.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-provider-settings",
                    }
                }
            ]
        },


        // logbook
        {
            path: "logbook",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "logbook-list",
                    component: () => import("../pages/Logbook/LogbookList/LogbookList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-logbook-list",
                    },
                },
                {
                    path: "combined",
                    name: "logbook-combined-list",
                    component: () => import("../pages/Logbook/LogbookList/LogbookCombinedList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-logbook-combined-list",
                    },
                },
                {
                    path: ":id",
                    name: "logbook-edit",
                    component: () => import("../pages/Logbook/LogbookEdit/LogbookEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-logbook-edit",
                    },
                },
                {
                    path: "settings",
                    name: "logbook-settings",
                    component: () => import("../pages/Logbook/LogbookSettings/LogbookSettings.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-logbook-settings",
                    },
                },
            ]
        },


        {
            path: "license-management",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "licenses",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "license-license-list",
                            component: () => import("../pages/License/License/LicenseList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-license-list",
                            }
                        },
                        {
                            path: "add",
                            name: "license-license-add",
                            component: () => import("../pages/License/License/LicenseAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-license-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "license-license-edit",
                            component: () => import("../pages/License/License/LicenseEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-license-edit",
                            }
                        }
                    ]
                },
                {
                    path: "partners",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "license-partner-list",
                            component: () => import("../pages/License/Partner/PartnerList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-partner-list",
                            }
                        },
                        {
                            path: "add",
                            name: "license-partner-add",
                            component: () => import("../pages/License/Partner/PartnerAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-partner-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "license-partner-edit",
                            component: () => import("../pages/License/Partner/PartnerEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-partner-edit",
                            }
                        }
                    ]
                },
                {
                    path: "products",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "license-product-list",
                            component: () => import("../pages/License/Product/ProductList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-product-list",
                            }
                        },
                        {
                            path: "add",
                            name: "license-product-add",
                            component: () => import("../pages/License/Product/ProductAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-product-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "license-product-edit",
                            component: () => import("../pages/License/Product/ProductEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-product-edit",
                            }
                        }
                    ]
                },
                {
                    path: "modules",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "license-module-list",
                            component: () => import("../pages/License/Module/ModuleList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-module-list",
                            }
                        },
                        {
                            path: "add",
                            name: "license-module-add",
                            component: () => import("../pages/License/Module/ModuleAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-module-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "license-module-edit",
                            component: () => import("../pages/License/Module/ModuleEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-module-edit",
                            }
                        }
                    ]
                },
                {
                    path: "product-types",
                    component: {render: () => h(RouterView)},
                    children: [
                        {
                            path: "",
                            name: "license-productType-list",
                            component: () => import("../pages/License/ProductType/ProductTypeList.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-productType-list",
                            }
                        },
                        {
                            path: "add",
                            name: "license-productType-add",
                            component: () => import("../pages/License/ProductType/ProductTypeAdd.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-productType-add",
                            }
                        },
                        {
                            path: ":id",
                            name: "license-productType-edit",
                            component: () => import("../pages/License/ProductType/ProductTypeEdit.vue"),
                            meta: {
                                requiresAuth: true,
                                title: "page-title-license-productType-edit",
                            }
                        }
                    ]
                },
            ],
        },


        {
            path: "alerts",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "",
                    name: "alerts-list",
                    component: () => import("../pages/Alerts/AlertsList.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-alerts-list",
                    },
                },
                {
                    path: "settings",
                    name: "alerts-settings",
                    component: () => import("../pages/Alerts/SettingsAlerts.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-alerts-settings",
                    },
                }
            ],
        },


        // Settings
        {
            path: "settings",
            component: {render: () => h(RouterView)},
            children: [
                {
                    path: "basic",
                    name: "settings-basic-data-edit",
                    component: () => import("../pages/Settings/ProviderEdit/SettingsBasicEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-settings-basic-edit",
                    },
                },
                {
                    path: "design",
                    name: "settings-design-edit",
                    component: () => import("../pages/Settings/ProviderEdit/SettingsDesignEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-settings-design-edit",
                    },
                },
                {
                    path: "integrations",
                    name: "settings-third-party-edit",
                    component: () => import("../pages/Settings/ProviderEdit/SettingsThirdPartyEdit.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-settings-thirdParty-edit",
                    },
                },
                {
                    path: "messages",
                    name: "settings-messages",
                    component: () => import("../pages/Settings/SettingsMessage.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-settings-messages",
                    },
                },
                {
                    path: "translations",
                    name: "settings-translations",
                    component: () => import("../pages/Settings/SettingsTranslation.vue"),
                    meta: {
                        requiresAuth: true,
                        title: "page-title-settings-translation",
                    },
                },
            ]
        },


        // {
        //   path: "profile",
        //   name: "profile",
        //   component: () => import("../pages/User/Profile.vue"),
        //   meta: {
        //     requiresAuth: true,
        //     title: "page-title-profile",
        //   },
        // },
        {
            path: "logout",
            name: "logout",
            component: () => import("../pages/Login/Logout.vue"),
            meta: {
                requiresAuth: true,
            },
        },
    ],
};

const unauthenticated = {
    path: "",
    redirect: "login",
    component: Unauthenticated,
    children: [
        // {
        //     path: "loading",
        //     name: "splash",
        //     component: () => import("../pages/SplashScreen.vue"),
        //     meta: {
        //         requiresAuth: false
        //     },
        // },
        // {
        //     path: "p/:name",
        //     name: "provider-landing-page",
        //     component: () => import("../pages/LandingPage.vue"),
        //     meta: {
        //         requiresAuth: false
        //     },
        // },
        {
            path: "login",
            name: "login",
            component: () => import("../pages/Login/Login.vue"),
            meta: {
                requiresAuth: false,
                title: "page-title-login",
            },
        },
        // {
        //     path: "register",
        //     name: "register",
        //     component: () => import("../pages/Register/Register.vue"),
        //     meta: {
        //         requiresAuth: false
        //     },
        // },
        // {
        //   path: "verify-email",
        //   name: "verify-email",
        //   component: () => import("../pages/Login/EmailVerify.vue"),
        //   meta: {
        //     requiresAuth: false,
        //     title: "page-title-verify-email",
        //   },
        // },
        {
            path: "reset-password",
            name: "reset-password",
            component: () => import("../pages/Login/ResetPassword.vue"),
            meta: {
                requiresAuth: false,
                title: "page-title-reset-password",
            },
        },
    ],
};

const routes = [
    {
        path: "/",
        redirect: "",
        component: Unauthenticated,
        children: [
            unauthenticated,
            authenticated,
            {
                path: "/:pathMatch(.*)*",
                name: "Error",
                component: () => import("../pages/Login/Login.vue"),
                meta: {
                    requiresAuth: false,
                    title: "page-title-login",
                },
            },
            // {
            //     path: "/:pathMatch(.*)*",
            //     name: "Error",
            //     component: () => import("../pages/Errors/Default.vue"),
            //     meta: {
            //         requiresAuth: false
            //     },
            // },
        ],
    },
];

export default routes;
