import { getDataSet, reduce, findRegionByCode } from "iso3166-2-db";
import { isValidIBAN, isValidBIC, electronicFormatIBAN } from 'ibantools';
import validator from 'validator';

import {
    getCountries,
    getCountryCallingCode,
    parsePhoneNumber,
} from "libphonenumber-js";
import { useI18n } from "vue-i18n";

import * as yup from "yup";
import {computed} from "vue";
import {useStore} from "vuex";

export default function useTools() {
    const { locale } = useI18n({ useScope: "global" });
    const lang = locale.value?.substring(0, 2);
    const countryList = reduce(getDataSet(), lang);
    const { t } = useI18n();

    const isEmptyString = (stringToCheck) => {
        if (
            stringToCheck === "" ||
            stringToCheck === null ||
            typeof stringToCheck === "undefined"
        ) {
            return true;
        } else {
            return false;
        }
    };

    const resizeImage = (base64Str, maxWidth = 1000, maxHeight = 1000) => {
        return new Promise((resolve) => {
            let img = new Image();
            img.src = base64Str;
            img.onload = () => {
                let canvas = document.createElement("canvas");
                const MAX_WIDTH = maxWidth;
                const MAX_HEIGHT = maxHeight;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                resolve(canvas.toDataURL());
            };
        });
    };

    const getCountryList = (selectedOnTop = true) => {
        let countries = [];

        if (selectedOnTop) {
            countries.push({ name: countryList["AT"].name, value: "AT" });
            countries.push({ name: countryList["DE"].name, value: "DE" });
            countries.push({ name: countryList["CH"].name, value: "CH" });
        }

        Object.keys(countryList)
            .sort((a, b) => (countryList[a].name > countryList[b].name ? 1 : -1))
            .forEach((code) => {
                if (
                    !selectedOnTop ||
                    (selectedOnTop && !["AT", "DE", "CH"].includes(code))
                ) {
                    countries.push({ name: countryList[code].name, value: code });
                }
            });

        return countries;
    };

    const getCountryFromCode = (code) => {
        return countryList?.[code]?.name;
    };

    const getRegionFromCode = (code) => {
        const region = findRegionByCode(code);
        return region[0]?.names?.[lang] || region[0]?.names?.en;
    };

    const getRegionsForCountry = (code) => {
        let regions = [];
        if (code) {
            regions = countryList[code].regions
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((region) => ({
                    name: region.name,
                    value: `${code}-${region.iso}`,
                }));
        }
        return regions;
    };

    const getPhoneCountryList = () => {
        let supportedCountries = getCountries();
        let countries = getCountryList(false);
        let phoneCountries = [];

        countries.forEach((country) => {
            if (supportedCountries.includes(country.value)) {
                phoneCountries.push({
                    name: country.name,
                    iso2: country.value,
                    dialCode: getCountryCallingCode(country.value),
                });
            }
        });

        return phoneCountries;
    };

    const castPhoneNumber = (number, country = null) => {
        let phoneNumber;
        if (country !== null) {
            phoneNumber = parsePhoneNumber(number, country);
        } else {
            phoneNumber = parsePhoneNumber(number);
        }

        return phoneNumber;
    };

    const formatPhoneNumber = (number, country = null) => {
        if (number && number.trim() !== "") {
            return castPhoneNumber(number, country).format("INTERNATIONAL");
        }
        return null;
    };

    const formatBytes = (bytes, decimals = 1) => {
        if (bytes === 0) return "0 Bytes";

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        // console.log("formatBytes", parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i])
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const formatPhoneNumberUrl = (number, country = null) => {
        if (number && number.trim() !== "") {
            return castPhoneNumber(number, country).format("RFC3966");
        }
        return null;
    };

    // Checks if Webview is in APP-shell
    const isNativeApp = () => {
        return !!(typeof window !== "undefined" && window.ReactNativeWebView);
    };

    const generateErrorMessage = (serverError) => {
        if(!serverError || ( !serverError.message && !serverError.errors )) {
            return null;
        }

        const error = {
            component: ({ message, errors }) => {
                return (
                    <div>
                        {message}
                        {errors && typeof errors === "object" && (
                            <ul class="list-disc list-inside ml-2">
                                {Object.values(errors).map((errGroup) =>
                                    errGroup?.map((err) => <li>{err}</li>)
                                )}
                            </ul>
                        )}
                    </div>
                );
            },

            props: {
                message: serverError?.message,
                errors: serverError?.errors,
            },
        };

        return error;
    };

    const getValue = (objectPath, values) => {
        return objectPath.split(".").reduce((r, k) => r?.[k], values);
    };

    const object2Yup = (object) => {
        const tempObject = { ...object };
        Object.entries(tempObject).map(([key, value]) => {
            if (!value?.type) {
                tempObject[key] = yup.object(object2Yup(value));
            } else {
                tempObject[key] = value;
            }
        });
        return tempObject;
    };

    const setFieldValues = (level, obj, values, initValues) => {
        // console.log("obj", obj)
        if (obj?.translations) {
            obj.translations = { ...obj.translations }
        }
        const result = {};
        // For each object path (property key) in the object
        for (const objectPath in obj) {
            if (obj[objectPath] > level) {
                // Split path into component parts
                const parts = objectPath.split(".");
                // Create sub-objects along path as needed
                let target = result;
                while (parts.length > 1) {
                    const part = parts.shift();
                    target = target[part] = target[part] || {};
                }
                // Set value at end of path
                let value = getValue(objectPath, values);
                if (typeof value === "undefined" || value === null) {
                    value = getValue(objectPath, initValues);
                }
                target[parts[0]] = value === null ? undefined : value;
            }
        }

        // console.log("setFieldVaues", result)

        return result;
    };

    const generateFormData = (fieldAccess, schema, initialValues, object) => {
        // console.log("generateFormData field Access", fieldAccess)
        // console.log("generateFormData schema", schema)
        // console.log("generateFormData initialValues", initialValues)
        // console.log("generateFormData object", object)
        const schemaObject = setFieldValues(1, fieldAccess, schema);
        const tempSchema = object2Yup(schemaObject);
        const initValues = setFieldValues(0, fieldAccess, object, initialValues);

        return { schema: yup.object(tempSchema), initVals: initValues };
    };

    const removeKey = (obj, path) => {
        const backupObject = obj;
        if (!obj || !path) {
            return;
        }

        if (typeof path === "string") {
            path = path.split(".");
        }

        for (var i = 0; i < path.length - 1; i++) {
            obj = obj[path[i]];
            if (typeof obj === "undefined") {
                return;
            }
        }

        delete obj[path.pop()];
        if (JSON.stringify(obj) === "{}") {
            delete backupObject[path.pop()];
        }
    };

    const deepEqual = (x, y) => {
        const ok = Object.keys,
            isDate = Object.prototype.toString.call(x) === "[object Date]",
            tx = typeof x,
            ty = typeof y;
        return x && y && tx === "object" && !isDate && tx === ty
            ? ok(x).length === ok(y).length &&
            ok(x).every((key) => {
                return deepEqual(x[key], y[key]);
            })
            : isDate
                ? x.toString() === y.toString()
                : x === y;
    };

    const store = useStore();
    // const length= computed(() => store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinLength);
    // const lowerCase= computed(() => store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinLowercase);
    // const upperCase= computed(() => store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinUppercase);
    // const numbers= computed(() => store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinNumbers);
    // const symbols= computed(() => store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinSymbols);
    // const required= computed(() => store.getters.getProviderConfiguration?.['user']?.passwordPolicyRequiredCombinations);

    const validatePW = (password) => {
        const length = store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinLength

        if (password.length < length) return false;
        const passwordPolicyMinLowercase =store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinLowercase
        const passwordPolicyMinUppercase =store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinUppercase
        const passwordPolicyMinNumbers =store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinNumbers
        const passwordPolicyMinSymbols =store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinSymbols
        const minConditions =store.getters.getProviderConfiguration?.['user']?.passwordPolicyRequiredCombinations

        let metConditions = 0;
        // Check for lowercase letters and count them
        const lowercaseCount = (password.match(/[a-z]/g) || []).length;
        if (lowercaseCount >= passwordPolicyMinLowercase) metConditions++;

        // Check for uppercase letters and count them
        const uppercaseCount = (password.match(/[A-Z]/g) || []).length;
        if (uppercaseCount >= passwordPolicyMinUppercase) metConditions++;

        // Check for numbers and count them
        const numbersCount = (password.match(/\d/g) || []).length;
        if (numbersCount >= passwordPolicyMinNumbers) metConditions++;

        // Check for symbols and count them
        const symbolsCount = (password.match(/[@$!%*?&.#+~:;]/g) || []).length;
        if (symbolsCount >= passwordPolicyMinSymbols) metConditions++;

        return metConditions >= minConditions;
    }

    const checkValidUrl = (string) => {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    };

    const getNestedValue = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };
    const validateField = (settingName, field, value, rules, formData = {}) => {
        // Basic validation logic for each rule
        // console.log("field", field)
        // if (field === 'validFrom') {
        //   console.log("value", value)
        //   console.log("rules", rules)
        if (!rules || !Array.isArray(rules)) {
            // console.log("no rules")
            return
        }
        //   // console.log("rule of de includes requireed", rules?.includes('required'))
        // }
        // console.log("rules", rules)
        if (rules?.includes('nullable') && !value || rules?.includes('nullable') && value === '') {
            return '';
        }
        if (rules?.includes('required')) {
            // console.log("required", field)
            // Check specifically for null, undefined, empty string, and empty object
            const isEmpty = value === null || value === undefined || value === '' ||
                (typeof value === 'object' && !(value instanceof Date) && Object.keys(value).length === 0) ||
                (rules.includes('number') && value !== 0 && !value);
            if (isEmpty) {
                // console.log("sry, but value is required");
                return t('form-validation-required');
            }
        }
        // Array validation
        if (rules?.includes('array') && (!Array.isArray(value) || value?.length < 1)) {
            // console.log("sry, but value is required array");
            return t('form-validation-required');
        }
        if (rules?.includes('password')) {
            if (!validatePW(value)) {
                return t("form-validation-password-validation-not-matching-requirements", {
                    length: store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinLength,
                    lowerCase: store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinLowercase,
                    upperCase: store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinUppercase,
                    numbers: store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinNumbers,
                    symbols: store.getters.getProviderConfiguration?.['user']?.passwordPolicyMinSymbols,
                    required: store.getters.getProviderConfiguration?.['user']?.passwordPolicyRequiredCombinations
                })
            }
        }

        // same than validation
        if (rules?.some(rule => rule?.startsWith('same:'))) {
            // console.log("bigger check")
            // console.log("value", value);
            // console.log("formData", formData);
            const afterField = rules?.find(rule => rule.startsWith('same:')).split(':')[1];
            // console.log("afterField", afterField);
            const afterFieldValue = afterField.includes('.') ? formData[afterField.split(".")[0]][afterField.split(".")[1]] : formData[afterField]
            // console.log("afterFieldValue", afterFieldValue);
            if (afterFieldValue !== value) {
                return t('form-validation-field-same', { field: t(settingName + '-label-' + afterField) })
            }
        }

        const regexRule = rules?.find(rule => rule?.startsWith('regex:'));
        if (regexRule) {
            const [, pattern] = regexRule.split('regex:');
            const regex = new RegExp(pattern);
            // console.log("regex", regex);
            // console.log("value", value)
            // console.log("regex test", regex.test(value))
            if (!regex.test(value)) {
                return t('form-validation-regex');
            }
        }

        // bigger than validation
        if (rules?.some(rule => rule?.startsWith('bigger:'))) {
            // console.log("bigger check")
            // console.log("value", value);
            // console.log("formData", formData);
            const afterField = rules?.find(rule => rule.startsWith('bigger:')).split(':')[1];
            // console.log("afterField", afterField);
            const afterFieldValue = afterField.includes('.') ? formData[afterField.split(".")[0]][afterField.split(".")[1]] : formData[afterField]
            // console.log("afterFieldValue", afterFieldValue);
            if (Number(afterFieldValue) >= Number(value)) {
                return t('form-validation-field-bigger', { field: t(settingName + '-label-' + afterField) })
            }
        }

        //smaller than validation
        if (rules?.some(rule => rule?.startsWith('smaller:'))) {
            // console.log("bigger check")
            // console.log("value", value);
            // console.log("formData", formData);
            const afterField = rules?.find(rule => rule.startsWith('smaller:')).split(':')[1];
            // console.log("afterField", afterField);
            const afterFieldValue = afterField.includes('.') ? formData[afterField.split(".")[0]][afterField.split(".")[1]] : formData[afterField]
            // console.log("afterFieldValue", afterFieldValue);
            if (Number(afterFieldValue) <= Number(value)) {
                return t('form-validation-field-smaller', { field: t(settingName + '-label-' + afterField) })
            }
        }

        // equal or bigger validation
        if (rules?.some(rule => rule?.startsWith('equalOrBigger:'))) {
            const afterField = rules?.find(rule => rule.startsWith('equalOrBigger:')).split(':')[1];
            const afterFieldValue = afterField.includes('.') ? formData[afterField.split(".")[0]][afterField.split(".")[1]] : formData[afterField];
            if (Number(afterFieldValue) > Number(value)) {
                return t('form-validation-field-equalOrBigger', { field: t(settingName + '-label-' + afterField) });
            }
        }

        // equal or smaller validation
        if (rules?.some(rule => rule?.startsWith('equalOrSmaller:'))) {
            const afterField = rules?.find(rule => rule.startsWith('equalOrSmaller:')).split(':')[1];
            const afterFieldValue = afterField.includes('.') ? formData[afterField.split(".")[0]][afterField.split(".")[1]] : formData[afterField];
            if (Number(afterFieldValue) < Number(value)) {
                return t('form-validation-field-equalOrSmaller', { field: t(settingName + '-label-' + afterField) });
            }
        }

        // Max string length
        if (rules?.some(rule => rule?.startsWith('max:'))) {
            const maxValue = parseInt(rules?.find(rule => rule.startsWith('max:')).split(':')[1]);
            if (typeof rules.includes('string') && value?.length > maxValue) {
                return t('form-validation-string-max', { max: maxValue });
            } else if (rules.includes('number') && Number(value) > maxValue) {
                return t('form-validation-max', { max: maxValue });
            }
        }

        // if (field === 'min') {
        //     const minValue = parseInt(rules?.find(rule => rule.startsWith('min:')).split(':')[1]);
        //     console.log("minValue", minValue)
        //     console.log("value", value)
        // }

        // Min string length
        if (rules?.some(rule => rule?.startsWith('min:'))) {
            const minValue = parseInt(rules?.find(rule => rule.startsWith('min:')).split(':')[1]);
            if (rules.includes('string') && value?.length < minValue) {
                return t('form-validation-string-min', { min: minValue });
            } else if (rules.includes('number') && Number(value) < minValue) {
                return t('form-validation-min', { min: minValue });
            }
        }

        // After specific date validation
        //     console.log("formData", formData)
        if (rules?.some(rule => rule?.startsWith('after:')) && !isNaN(Date.parse(value))) {
            const afterField = rules?.find(rule => rule.startsWith('after:')).split(':')[1];

            // console.log("afterField", afterField)
            const afterDate = getNestedValue(formData, afterField);
            if (new Date(value) <= new Date(afterDate)) {
                return t('form-validation-date-after', { field: t('registration-tariffs-label-' + afterField) });
            }
        }

        // IBAN validation
        if (rules?.includes('iban') && !isValidIBAN(electronicFormatIBAN(value))) {
            return t('form-validation-iban');
        }

        // BIC validation
        if (rules?.includes('bic') && !isValidBIC(value)) {
            return t('form-validation-bic');
        }

        // Email validation
        if (rules?.includes('email') && !validator.isEmail(value)) {
            return t('form-validation-email');
        }
        return '';
    };

    const validateFormData = (settingName, previousFormData, newVal, validationSchema, requiredObj, errorsObj) => {
        const newErrors = errorsObj ? { ...errorsObj } : {};
        // console.log("newErrors at start", newErrors);
        // console.log("requiredObj", requiredObj)
        if (!requiredObj) return;
        Object.keys(requiredObj).forEach(key => {
            const keys = key.split('.');
            let oldVal = previousFormData;
            let currentVal = newVal;
            let rules = validationSchema;

            try {
                keys.forEach(subKey => {
                    oldVal = oldVal && oldVal[subKey];
                    currentVal = currentVal && currentVal[subKey];
                    rules = rules && rules[subKey];
                });

                // console.log("rules", rules)
                // console.log("currentVal", currentVal)
                // console.log("oldVal", oldVal)
                // Only validate if the value has changed or if errorsObj is not provided (after submit)
                if (!errorsObj || oldVal !== currentVal || rules?.some(rule => rule?.startsWith('after:')) || rules?.some(rule => rule?.startsWith('smaller:')) || rules?.some(rule => rule?.startsWith('bigger:')) || rules?.some(rule => rule?.startsWith('equalOrSmaller:')) || rules?.some(rule => rule?.startsWith('equalOrBigger:'))) {
                    // console.log('Validate key', key)
                    const error = validateField(settingName, key, currentVal, rules, newVal);
                    // let fieldKey = key.split('.')[1] || key;
                    // console.log("error", error)
                    if (error) {
                        newErrors[key] = error;
                    } else {
                        delete newErrors[key];
                    }
                }
            } catch (error) {
                console.warn(`Error accessing values for key ${key}:`, error);
            }
        });

        // console.log("new errors", newErrors)
        return newErrors;
    };


    const updateRequiredFields = (schema) => {
        const requiredFields = {};

        const extractRequired = (obj, path = '') => {
            Object.keys(obj).forEach(key => {
                const fullPath = path ? `${path}.${key}` : key;
                const value = obj[key];

                // console.log("valuie", value)
                // Check if the field is an object (nested schema)
                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    extractRequired(value, fullPath);
                } else {
                    // Check if 'required' is in the validation array
                    if (value?.includes('required') || value?.some(rule => rule?.startsWith('max:')) || value?.some(rule => rule?.startsWith('bigger:')) || value?.some(rule => rule?.startsWith('smaller:')) || value?.some(rule => rule?.startsWith('equalOrSmaller:') || value?.some(rule => rule?.startsWith('equalOrBigger:'))) || value?.some(rule => rule?.startsWith('after:'))) {
                        requiredFields[fullPath] = true;
                    }
                }
            });
        };

        extractRequired(schema);
        return requiredFields;
    };



    const addErrorIfAny = (field, error, obj) => {
        if (error) {
            obj[field] = error;
        }
        return obj;
    };

    const convertToCurrency = (value) => {
        return new Intl.NumberFormat(locale.value, {
            style: "currency",
            currency: store.getters.getProvider?.options?.currency || 'EUR',
        }).format(value);
    };

    const convertToMileage = (value) => {
        return new Intl.NumberFormat(locale.value, {
            style: "unit",
            unit: "kilometer",
            unitDisplay: "short",
        }).format(value);
    };

    const waitForDamageCaseCategories = () => {
        return new Promise((resolve) => {
            const checkDataAvailability = () => {
                // console.log("checkAvailability", store.getters.getVehicleCategoriesDone)
                if (store.getters.getVehicleCategoriesDone) {
                    resolve();
                } else {
                    setTimeout(checkDataAvailability, 100);
                }
            };
            checkDataAvailability();
        });
    };

    return {
        isEmptyString,
        resizeImage,
        getCountryList,
        getCountryFromCode,
        getRegionsForCountry,
        getRegionFromCode,
        getPhoneCountryList,
        formatPhoneNumber,
        formatBytes,
        formatPhoneNumberUrl,
        isNativeApp,
        generateErrorMessage,
        generateFormData,
        deepEqual,
        checkValidUrl,
        removeKey,
        validateField,
        validateFormData,
        updateRequiredFields,
        addErrorIfAny,
        setFieldValues,
        convertToCurrency,
        convertToMileage,
        waitForDamageCaseCategories
    };
}
